import { Box, FormControlLabel, Tab, Tabs, TextField } from "@material-ui/core";
import React, { Fragment } from "react";
import ReactMarkdown from "react-markdown";
import CMOButton from "../../components/Button/Button";
import LayoutHeaderFooter from "../../components/LayoutHeaderFooter/LayoutHeaderFooter";
import "./AccountantPage.scss";
import Dialog from "@material-ui/core/Dialog";
import { Close } from "@material-ui/icons";
import recaptchaRef from "../ContactForm/recaptchaRef";
import ReCAPTCHA from "react-google-recaptcha";
import CMOCheckbox from "../ContactForm/Checkbox/Checkbox";
import ClockLoader from "react-spinners/ClockLoader";
import Snackbar from "@material-ui/core/Snackbar";
import { css } from "@emotion/core";
import SuccessCheckMark from "../../components/SuccessCheckMark/SuccessCheckMark";
import { Alert } from "../RequestForm/RequestForm";
import rehypeRaw from "rehype-raw";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

class AccountantPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      isModalOpen: false,
      form: {
        headquarterAddress: "",
        siteAddress: "",
        deliveryAddress: "",
        contactName: "",
        taxNumber: "",
        companyName: "",
        emailAddress: "",
        website: "",
        phone: "",
        agreement: false,
      },
      errors: {
        headquarterAddressError: false,
        siteAddressError: false,
        deliveryAddressError: false,
        websiteError: false,
        contactNameError: false,
        taxNumberError: false,
        companyNameError: false,
        emailAddressError: "",
        phoneError: false,
        agreementError: false,
        snackbarError: false,
      },
      emailSuccess: false,
      loader: false,
      snackbar: false,
    };

    this.openModal = this.openModal.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.onChange = this.onChange.bind(this);
    this.getResult = this.getResult.bind(this);
    this.handleSnackBarClose = this.handleSnackBarClose.bind(this);
    this.contactNameValidation = this.contactNameValidation.bind(this);
    this.companyNameValidation = this.companyNameValidation.bind(this);
    this.emailValidation = this.emailValidation.bind(this);
    this.phoneValidation = this.phoneValidation.bind(this);
    this.headquarterAddressValidation =
      this.headquarterAddressValidation.bind(this);
    this.deliveryAddressValidation = this.deliveryAddressValidation.bind(this);
    this.siteAddressValidation = this.siteAddressValidation.bind(this);
    this.emailCheck = this.emailCheck.bind(this);
    this.taxNumberValidation = this.taxNumberValidation.bind(this);
    this.agreementValidation = this.agreementValidation.bind(this);
    this.websiteValidation = this.websiteValidation.bind(this);
    this.formvalidations = this.formvalidations.bind(this);
  }

  openModal() {
    this.setState({ isModalOpen: true });
  }

  handleModalClose() {
    this.setState({
      isModalOpen: false,
      emailSuccess: false,
      form: {
        headquarterAddress: "",
        siteAddress: "",
        deliveryAddress: "",
        contactName: "",
        taxNumber: "",
        companyName: "",
        emailAddress: "",
        website: "",
        phone: "",
        agreement: false,
      },
      errors: {
        headquarterAddressError: false,
        siteAddressError: false,
        deliveryAddressError: false,
        websiteError: false,
        contactNameError: false,
        taxNumberError: false,
        companyNameError: false,
        emailAddressError: "",
        phoneError: false,
        agreementError: false,
        snackbarError: false,
      },
    });
  }

  handleChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  onChange(token) {
    this.setState({ loader: true });
    const {
      contactName,
      companyName,
      emailAddress,
      phone,
      taxNumber,
      website,
      headquarterAddress,
      siteAddress,
      deliveryAddress,
    } = this.state.form;

    fetch(`${process.env.GATSBY_AZURE_URL}/lead-giver`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        recaptcha: token,
        contactName,
        companyName,
        emailAddress,
        phone,
        taxNumber,
        website,
        headquarterAddress,
        siteAddress,
        deliveryAddress,
      }),
    })
      .then((data) => {
        if (data.status === 200) {
          this.setState({ loader: false, emailSuccess: true, form: {} });
        } else {
          this.setState({
            loader: false,
            emailSuccess: false,
            errors: { ...this.state.errors, snackbarError: true },
          });
        }
      })
      .catch((e) => {
        this.setState({
          loader: false,
          emailSuccess: false,
          errors: { ...this.state.errors, snackbarError: true },
        });
      });
  }

  getResult() {
    recaptchaRef.current.execute();
  }

  handleSnackBarClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      snackbar: false,
      errors: { ...this.state.errors, snackbarError: false },
    });
  }

  contactNameValidation() {
    if (this.state.form.contactName === "") {
      this.setState({
        errors: { ...this.state.errors, contactNameError: true },
      });
      return false;
    } else {
      this.setState({
        errors: { ...this.state.errors, contactNameError: false },
      });
      return true;
    }
  }

  companyNameValidation() {
    if (this.state.form.companyName === "") {
      this.setState({
        errors: { ...this.state.errors, companyNameError: true },
      });
      return false;
    } else {
      this.setState({
        errors: { ...this.state.errors, companyNameError: false },
      });
      return true;
    }
  }

  emailValidation() {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regex.test(this.state.form.emailAddress);
  }

  phoneValidation() {
    if (this.state.form.phone === "") {
      this.setState({ errors: { ...this.state.errors, phoneError: true } });
      return false;
    } else {
      this.setState({ errors: { ...this.state.errors, phoneError: false } });
      return true;
    }
  }

  emailCheck() {
    if (this.state.form.emailAddress === "") {
      this.setState({
        errors: {
          ...this.state.errors,
          emailAddressError: "Kötelezően kitöltendő",
        },
      });
      return false;
    } else {
      if (this.emailValidation()) {
        this.setState({
          errors: { ...this.state.errors, emailAddressError: "" },
        });
        return true;
      } else {
        this.setState({
          errors: {
            ...this.state.errors,
            emailAddressError: "Nem megfelelő formátum",
          },
        });
        return false;
      }
    }
  }

  taxNumberValidation() {
    if (this.state.form.taxNumber === "") {
      this.setState({ errors: { ...this.state.errors, taxNumberError: true } });
      return false;
    } else {
      this.setState({
        errors: { ...this.state.errors, taxNumberError: false },
      });
      return true;
    }
  }

  agreementValidation() {
    if (this.state.form.agreement === false) {
      this.setState({ errors: { ...this.state.errors, agreementError: true } });
      return false;
    } else {
      this.setState({
        errors: { ...this.state.errors, agreementError: false },
      });
      return true;
    }
  }

  headquarterAddressValidation() {
    if (this.state.form.headquarterAddress === "") {
      this.setState({
        errors: { ...this.state.errors, headquarterAddressError: true },
      });
      return false;
    } else {
      this.setState({
        errors: { ...this.state.errors, headquarterAddressError: false },
      });
      return true;
    }
  }

  siteAddressValidation() {
    if (this.state.form.siteAddress === "") {
      this.setState({
        errors: { ...this.state.errors, siteAddressError: true },
      });
      return false;
    } else {
      this.setState({
        errors: { ...this.state.errors, siteAddressError: false },
      });
      return true;
    }
  }

  deliveryAddressValidation() {
    if (this.state.form.deliveryAddress === "") {
      this.setState({
        errors: { ...this.state.errors, deliveryAddressError: true },
      });
      return false;
    } else {
      this.setState({
        errors: { ...this.state.errors, deliveryAddressError: false },
      });
      return true;
    }
  }

  websiteValidation() {
    if (this.state.form.website === "") {
      this.setState({
        errors: { ...this.state.errors, websiteError: true },
      });
      return false;
    } else {
      this.setState({
        errors: { ...this.state.errors, websiteError: false },
      });
      return true;
    }
  }

  async formvalidations() {
    let contactNameResult = await this.contactNameValidation();
    let companyNameResult = await this.companyNameValidation();
    let emailAddressResult = await this.emailCheck();
    let phoneResult = await this.phoneValidation();
    let taxNumberResult = await this.taxNumberValidation();
    let agreementResult = await this.agreementValidation();
    let headquarterAddressResult = await this.headquarterAddressValidation();
    let siteAddressResult = await this.siteAddressValidation();
    let deliveryAddressResult = await this.deliveryAddressValidation();
    let websiteResult = await this.websiteValidation();

    if (
      contactNameResult === false ||
      companyNameResult === false ||
      emailAddressResult === false ||
      phoneResult === false ||
      taxNumberResult === false ||
      agreementResult === false ||
      headquarterAddressResult === false ||
      siteAddressResult === false ||
      deliveryAddressResult === false ||
      websiteResult === false
    ) {
      this.setState({ emailSuccess: false });
      return false;
    } else {
      return true;
    }
  }

  render() {
    const override = css`
      display: block;
      margin: 0 auto;
      margin-bottom: 50px;
      margin-top: 100px;
      box-shadow: none;
      border: 4px solid #4caf50;
    `;

    const {
      PrimaryColor,
      SecondaryColor,
      Seo,
      contractItems,
      mapSection,
      pointsSection,
      productSection,
      retailerButton,
      retailerSection,
      shareItems,
      subtitle,
      title,
      topImage,
      processTitle,
      contractDescription,
      formTitle,
      successText,
    } = this.props.pageContext.content;

    const location_href = this.props.location.pathname;
    return (
      <LayoutHeaderFooter
        header_background_color={PrimaryColor}
        header_secondary_color={SecondaryColor}
        header_no_fade={false}
        SEO_title={Seo.title}
        SEO_description={Seo.description}
        SEO_keywords={Seo.keywords}
        SEO_image={Seo.image}
        SEO_location={location_href}
        main_class={"accountant-page"}
        footer_title={"Kérdése van?"}
        footer_subtitle={"Szakértő kollégánk és az ügyfélszolgálat szívesen segít!"}
      >
        <div
          className="top-section"
          style={{
            backgroundImage: `url(${
              process.env.GATSBY_CMS_URL + topImage.url
            })`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <h1>{title}</h1>
          <span className="subtitle">{subtitle}</span>
          <div className="buttons">
            {retailerButton.map((button, index) => (
              <a href={`#${button.key}`} key={`link-${index}`}>
                <CMOButton
                  variant="contained"
                  background_color={button.color}
                  text={button.label}
                  color={"white"}
                  key={`button-${index}`}
                  onClick={() => this.setState({ tabValue: index })}
                ></CMOButton>
              </a>
            ))}
          </div>
        </div>
        
        <div className="map-section">
          <img
            alt={mapSection.imageAlt}
            className="desktop-img"
            src={process.env.GATSBY_CMS_URL + mapSection.image.url}
            loading="lazy"
          ></img>
          <img
            alt={mapSection.imageMobileAlt}
            className="mobile-img"
            src={process.env.GATSBY_CMS_URL + mapSection.imageMobile.url}
            loading="lazy"
          ></img>
          <div className="content">
            <h2 className="title">{mapSection.title}</h2>
            <ReactMarkdown
              rehypePlugins={[rehypeRaw]}
              children={mapSection.content}
            ></ReactMarkdown>
          </div>
        </div>

        <div className="points-section">
          <h2 className="title">{pointsSection.title}</h2>
          <ul className="points">
            {pointsSection.point.map((entry, index) => (
              <li key={`point-${index}`}>
                <ReactMarkdown
                  rehypePlugins={[rehypeRaw]}
                  children={entry.label}
                ></ReactMarkdown>
              </li>
            ))}
          </ul>
        </div>

        { retailerSection ? (<div className="accountant-section">
          <img
            alt={retailerSection.imageAlt}
            className="desktop-img"
            src={process.env.GATSBY_CMS_URL + retailerSection.image.url}
            loading="lazy"
          ></img>
          <img
            alt={mapSection.imageMobileAlt}
            className="mobile-img"
            src={process.env.GATSBY_CMS_URL + retailerSection.imageMobile.url}
            loading="lazy"
          ></img>
          <div className="content">
            <h2 className="title">{retailerSection.title}</h2>
            <ReactMarkdown
              rehypePlugins={[rehypeRaw]}
              children={retailerSection.content}
            ></ReactMarkdown>
          </div>
          </div>): "" }
        

        <div className="products-section">
          <img
            alt={productSection.imageAlt}
            className="desktop-img"
            src={process.env.GATSBY_CMS_URL + productSection.image.url}
            loading="lazy"
          ></img>
          <img
            alt={mapSection.imageMobileAlt}
            className="mobile-img"
            src={process.env.GATSBY_CMS_URL + productSection.imageMobile.url}
            loading="lazy"
          ></img>
          <div className="content">
            <h2 className="title">{productSection.title}</h2>
            <ReactMarkdown
              rehypePlugins={[rehypeRaw]}
              children={productSection.content}
            ></ReactMarkdown>
          </div>
        </div>

        <div className="tabs-section">
          <h2 className="title">{processTitle}</h2>
          <Tabs
            value={this.state.tabValue}
            onChange={this.handleChange}
            indicatorColor="primary"
          >
            {retailerButton.map((button, index) => (
              <Tab
                key={`tab-button-${index}`}
                label={button.label}
                id={button.key}
              ></Tab>
            ))}
          </Tabs>
          <TabPanel value={this.state.tabValue} index={0}>
            <Fragment>
              <span className="description">{contractDescription}</span>
              <div className="contract-container">
                <div className="line"></div>
                {contractItems.map((item, index) => (
                  <div className="contract-item" key={`contract-item-${index}`}>
                    <div className="item-number">{index + 1}</div>
                    <div className="item-content">
                      <span className="item-title">{item.title}</span>
                      <span className="item-description">
                        {item.description}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </Fragment>
          </TabPanel>
          <TabPanel value={this.state.tabValue} index={1}>
            <div className="share-container">
              <span className="description">{contractDescription}</span>
              <div className="line"></div>
              {shareItems.map((item, index) => (
                <div className="share-item" key={`share-item-${index}`}>
                  <div className="item-number">{index + 1}</div>
                  <div className="item-content">
                    <span className="item-title">{item.title}</span>
                    <span className="item-description">{item.description}</span>
                  </div>
                </div>
              ))}
            </div>
          </TabPanel>
          <CMOButton
            variant="contained"
            background_color={"#2E0CFE"}
            text={"Kezdés"}
            color={"white"}
            onClick={async () => this.openModal()}
          ></CMOButton>
          <Dialog
            classes={{ paper: "MuiDialog-paper-accountant" }}
            open={this.state.isModalOpen}
            onClose={this.handleModalClose}
          >
            <div className="dialog-content">
              <Close
                className="close-icon"
                onClick={this.handleModalClose}
              ></Close>
              {!this.state.emailSuccess && <h2>{formTitle}</h2>}
              {!this.state.emailSuccess && (
                <Fragment>
                  <div className="form">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      size="invisible"
                      sitekey={process.env.GATSBY_CAPTCHA_SITE_KEY}
                      onChange={this.onChange}
                    />
                    <div className="column">
                      <TextField
                        label={"Cégnév"}
                        error={this.state.errors.companyNameError}
                        variant="outlined"
                        onChange={(e) => {
                          this.setState({
                            form: {
                              ...this.state.form,
                              companyName: e.target.value,
                            },
                            errors: {
                              ...this.state.errors,
                              companyNameError: false,
                            },
                            emailSuccess: false,
                          });
                        }}
                        value={this.state.form.companyName || ""}
                      />
                      <TextField
                        label={"Székhely"}
                        variant="outlined"
                        error={this.state.errors.headquarterAddressError}
                        onChange={(e) => {
                          this.setState({
                            form: {
                              ...this.state.form,
                              headquarterAddress: e.target.value,
                            },
                            errors: {
                              ...this.state.errors,
                              headquarterAddressError: false,
                            },
                            emailSuccess: false,
                          });
                        }}
                        value={this.state.form.headquarterAddress || ""}
                      />
                      <TextField
                        label={"Telephely"}
                        variant="outlined"
                        error={this.state.errors.siteAddressError}
                        onChange={(e) => {
                          this.setState({
                            form: {
                              ...this.state.form,
                              siteAddress: e.target.value,
                            },
                            errors: {
                              ...this.state.errors,
                              siteAddressError: false,
                            },
                            emailSuccess: false,
                          });
                        }}
                        value={this.state.form.siteAddress || ""}
                      />
                      <TextField
                        label={"Adószám"}
                        error={this.state.errors.taxNumberError}
                        variant="outlined"
                        placeholder="12345678-1-12"
                        onChange={(e) => {
                          this.setState({
                            form: {
                              ...this.state.form,
                              taxNumber: e.target.value,
                            },
                            errors: {
                              ...this.state.errors,
                              taxNumberError: false,
                            },
                            emailSuccess: false,
                          });
                        }}
                        value={this.state.form.taxNumber || ""}
                      />
                    </div>
                    <div className="column">
                      <TextField
                        label={"Szállítási cím"}
                        variant="outlined"
                        error={this.state.errors.deliveryAddressError}
                        onChange={(e) => {
                          this.setState({
                            form: {
                              ...this.state.form,
                              deliveryAddress: e.target.value,
                            },
                            errors: {
                              ...this.state.errors,
                              deliveryAddressError: false,
                            },
                            emailSuccess: false,
                          });
                        }}
                        value={this.state.form.deliveryAddress || ""}
                      />
                      <TextField
                        label={"Honlap címe"}
                        variant="outlined"
                        error={this.state.errors.websiteError}
                        onChange={(e) => {
                          this.setState({
                            form: {
                              ...this.state.form,
                              website: e.target.value,
                            },
                            errors: {
                              ...this.state.errors,
                              websiteError: false,
                            },
                            emailSuccess: false,
                          });
                        }}
                        value={this.state.form.website || ""}
                      />
                      <div className="row">
                        <TextField
                          label={"Telefonszám"}
                          variant="outlined"
                          placeholder="+36301234567"
                          error={this.state.errors.phoneError}
                          onChange={(e) => {
                            this.setState({
                              form: {
                                ...this.state.form,
                                phone: e.target.value,
                              },
                              errors: {
                                ...this.state.errors,
                                phoneError: false,
                              },
                              emailSuccess: false,
                            });
                          }}
                          value={this.state.form.phone || ""}
                        />
                        <TextField
                          label={"Email cím"}
                          variant="outlined"
                          placeholder="cmo@cmo.hu"
                          error={
                            this.state.errors.emailAddressError ? true : false
                          }
                          helperText={
                            this.state.errors.emailAddressError
                              ? this.state.errors.emailAddressError
                              : ""
                          }
                          onChange={(e) => {
                            this.setState({
                              form: {
                                ...this.state.form,
                                emailAddress: e.target.value,
                              },
                              errors: {
                                ...this.state.errors,
                                emailAddressError: false,
                              },
                              emailSuccess: false,
                            });
                          }}
                          value={this.state.form.emailAddress || ""}
                        />
                      </div>
                      <TextField
                        label={"Kapcsolattartó neve"}
                        error={this.state.errors.contactNameError}
                        variant="outlined"
                        onChange={(e) => {
                          this.setState({
                            form: {
                              ...this.state.form,
                              contactName: e.target.value,
                            },
                            errors: {
                              ...this.state.errors,
                              contactNameError: false,
                            },
                            emailSuccess: false,
                          });
                        }}
                        value={this.state.form.contactName || ""}
                      />
                    </div>
                  </div>
                  <div
                    className={
                      this.state.errors.agreementError
                        ? "agreement found"
                        : "agreement"
                    }
                  >
                    <FormControlLabel
                      control={
                        <CMOCheckbox
                          onChange={(e) => {
                            if (e.target.checked) {
                              this.setState({
                                form: { ...this.state.form, agreement: true },
                              });
                            } else {
                              this.setState({
                                form: { ...this.state.form, agreement: false },
                              });
                            }
                          }}
                        />
                      }
                      label={
                        <div className="agreement-text">
                          Elolvastam és elfogadom az{" "}
                          <a
                            href={"https://cmo.hu/adatkezelesi_tajekoztato.pdf"}
                            target={"__blank"}
                            rel={"noopener noreferrer nofollow"}
                          >
                            Adatkezelési tájékoztatót
                          </a>
                        </div>
                      }
                    />
                  </div>
                  <CMOButton
                    variant="contained"
                    background_color={"#5107FF"}
                    text={"Küldés"}
                    color={"white"}
                    onClick={async () => {
                      if (await this.formvalidations()) {
                        this.getResult();
                        //this.onChange();
                      }
                    }}
                  ></CMOButton>
                </Fragment>
              )}

              {this.state.loader ? (
                <ClockLoader
                  loading={this.state.loader}
                  css={override}
                  size={88}
                  color={"#4CAF50"}
                />
              ) : this.state.emailSuccess ? (
                <div className="success">
                  <SuccessCheckMark id={`id_accountan`} />
                  <ReactMarkdown
                    children={successText}
                    rehypePlugins={[rehypeRaw]}
                  ></ReactMarkdown>
                  <CMOButton
                    variant="contained"
                    background_color={"#5107FF"}
                    text={"Bezárás"}
                    color={"white"}
                    onClick={this.handleModalClose}
                  ></CMOButton>
                </div>
              ) : (
                ""
              )}
              <Snackbar
                open={this.state.errors.snackbarError}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                style={{ paddingTop: "100px" }}
                autoHideDuration={6000}
                onClose={this.handleSnackBarClose}
              >
                <Alert onClose={this.handleSnackBarClose} severity="error">
                  Hiba történt!
                </Alert>
              </Snackbar>
            </div>
          </Dialog>
        </div>
      </LayoutHeaderFooter>
    );
  }
}

export default AccountantPage;
